<template>
  <div id="contract-sign-info" :class="{ mobile: !$full }">
    <div
      class="background"
      :style="{
        backgroundImage: `url(${
          $full ? require('@/assets/SigningBg.jpg') : hotel.avatar
        })`,
      }"
    />
    <div class="gradient" />
    <div class="content">
      <div style="font-size: 21px">{{ $t('contract.infoTitle') }}</div>
      <b style="font-size: 24px"
        >{{ contract.lastName }} {{ contract.firstName }}</b
      >
      <div>
        {{ $t('contract.contractId') }} <b>{{ contract.id }}</b>
      </div>
      <div>
        {{ $t('contract.infoDescription') }}
      </div>
      <div class="table">
        <div class="row">
          <div class="title">{{ $t('contract.dateIn') }}</div>
          <div class="content">
            <i class="el-icon-date" />
            <div>{{ formattedDateIn }}</div>
          </div>
        </div>
        <div class="row">
          <div class="title">{{ $t('contract.dateOut') }}</div>
          <div class="content">
            <i class="el-icon-date" />
            <div>{{ formattedDateOut }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['contract', 'hotel'],
  computed: {
    formattedDateIn() {
      return this.contract.dateIn.toLocaleString('default', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
    },
    formattedDateOut() {
      return this.contract.dateOut.toLocaleString('default', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
    },
  },
}
</script>

<style lang="scss">
#contract-sign-info {
  width: 30%;
  min-width: 340px;
  max-width: 540px;
  min-height: 200px;
  background-color: rgb(178, 216, 231);
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &.mobile {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  > .background {
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  > .gradient {
    background: linear-gradient(
      to bottom,
      rgb(51 56 57 / 65%),
      rgb(43 36 40 / 90%) 80%,
      #1b1d1e
    );
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  > .content {
    color: white;
    max-width: 360px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 15px;
    font-weight: 300;
    b {
      font-weight: 500;
    }

    > .table {
      display: flex;
      flex-direction: column;
      color: white;
      gap: 10px;
      > .row {
        display: flex;
        > * {
          flex: 1;
        }
        > .title {
          text-align: left;
          padding-left: 15px;
        }
        > .content {
          display: flex;
          align-items: center;
          > i {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>
