<template>
  <el-form id="contract-agree-form" :model="form" :rules="rules">
    <el-form-item prop="agree">
      <el-checkbox v-model="form.agree"
        >{{ $t('contract.agree', [fullName]) }}
        <el-link
          type="primary"
          target="_blank"
          href="/files/signer_terms_ru.pdf"
          >{{ $t('contract.agree2') }}</el-link
        ></el-checkbox
      >
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: ['fullName'],
  data() {
    return {
      form: {
        agree: false,
      },
      rules: {
        agree: [
          {
            validator: (rule, checked, cb) => {
              cb(
                checked
                  ? undefined
                  : new Error(this.$t('contract.needToAgree')),
              )
            },
          },
        ],
      },
    }
  },
}
</script>

<style lang="scss">
#contract-agree-form {
  > .el-form-item {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    transition: 0.2s margin-bottom;
    &.is-error {
      margin-bottom: 22px;
    }

    .el-checkbox {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .el-checkbox__inner {
        width: 18px;
        height: 18px;
      }

      .el-checkbox__label {
        color: #606266;
        white-space: pre-wrap;
      }
    }
  }
}
</style>
