<template>
  <div id="contract-signed-info">
    <div class="title">
      {{ $t(isApproved ? 'contract.approvedTitle' : 'contract.signedTitle') }}
    </div>
    <img src="@/assets/ContractSigned.png" v-if="!isApproved" />
    <div class="description" v-html="description" />
    <div class="line"></div>
    <div class="table">
      <div class="row">
        <div class="title">{{ $t('contract.dateIn') }}</div>
        <div class="content">
          <i class="el-icon-date" />
          <div>{{ formattedDateIn }}</div>
        </div>
      </div>
      <div class="row">
        <div class="title">{{ $t('contract.dateOut') }}</div>
        <div class="content">
          <i class="el-icon-date" />
          <div>{{ formattedDateOut }}</div>
        </div>
      </div>
      <div class="row">
        <div class="title">{{ $t('contract.contractId') }}</div>
        <div class="content">
          <i class="el-icon-document" />
          <div>{{ contract.id }}</div>
        </div>
      </div>
    </div>
    <el-link
      class="download"
      @click="$emit('downloadContract')"
      v-if="contractAvailable"
    >
      <i class="el-icon-download" />
      {{ $t('contract.downloadContract') }}
    </el-link>
    <el-link
      class="download"
      @click="$emit('downloadInstruction')"
      v-if="stay.showInstruction"
    >
      <i class="el-icon-download" />
      {{ $t('contract.downloadInstruction') }}
    </el-link>
  </div>
</template>

<script>
export default {
  props: ['contract', 'stay', 'hotel'],
  computed: {
    emailLinkHtml() {
      return `<a href="mailto:${this.stay.propertyEmail}">${this.stay.propertyEmail}</a>`
    },
    formattedDateIn() {
      return this.contract.dateIn.toLocaleString('default', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
    },
    formattedDateOut() {
      return this.contract.dateOut.toLocaleString('default', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
    },
    isApproved() {
      return this.stay.contractSigningStatus == 'APPROVED'
    },
    contractAvailable() {
      return !this.stay.questionnaireMode
    },
    shortDocsUrl() {
      return new URL(this.hotel.docsUrl).host
    },
    description() {
      if (!this.isApproved) {
        if (this.hotel.docsUrl)
          return this.$t('contract.signedDescriptionWithUrl', [
            this.hotel.docsUrl,
            this.shortDocsUrl,
          ])

        return this.$t('contract.signedDescription', [this.emailLinkHtml])
      }

      if (this.contractAvailable) {
        return this.$t('contract.approvedDescription')
      } else {
        if (this.hotel.docsUrl)
          return this.$t('contract.makeSureDocsUploaded', [
            this.hotel.docsUrl,
            this.shortDocsUrl,
          ])
        else return this.$t('contract.approvedDescriptionWithoutDownload')
      }
    },
  },
}
</script>

<style lang="scss">
#contract-signed-info {
  max-width: 360px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 15px;
  padding: 25px;
  background-color: white;
  border-radius: 4px;
  z-index: 1;

  > .title {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 300;
    line-height: 1;
  }

  > .description {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  > .line {
    width: 100%;
    border-top: dashed 2px black;
  }

  > .table {
    display: flex;
    flex-direction: column;
    gap: 10px;
    > .row {
      display: flex;
      > * {
        flex: 1;
      }
      > .title {
        text-align: left;
        padding-left: 15px;
      }
      > .content {
        display: flex;
        align-items: center;
        > i {
          margin-right: 5px;
          color: #448afb;
          font-weight: 600;
        }
      }
    }
  }

  > .download {
    display: flex;
    align-items: center;
    i {
      font-weight: 600;
      margin-right: 3px;
    }
  }
}
</style>
