<script>
export default {
  data() {
    return {
      addressSuggestions: [],
      lastSelectedSuggestion: null,
      addressesUpdateTimeout: null,
    }
  },
  methods: {
    updateAddressSuggestions() {
      if (!this.contract.address) {
        this.addressSuggestions = []
        return
      }
      this.$externalApi
        .post(
          process.env.VUE_APP_DADATA_API_URL,
          {
            query: this.contract.address,
            locations: [
              {
                country: '*',
              },
            ],
          },
          {
            headers: {
              Authorization: 'Token ' + process.env.VUE_APP_DADATA_TOKEN,
            },
          },
        )
        .then(({ data }) => {
          this.addressSuggestions = data.suggestions.map(({ data, value }) => ({
            address: value,
            data,
            valid: Boolean(
              data.postal_code && data.house && (data.city || data.region),
            ),
          }))
        })
    },
    selectSuggestion(suggestion) {
      if (!suggestion.valid) return
      this.lastSelectedSuggestion = suggestion
      this.contract.address = suggestion.address
      this.contract.addressData = suggestion.data
      this.contract.city = suggestion.data.city || suggestion.data.region
      this.contract.postcode = suggestion.data.postal_code
      this.addressSuggestions = []
      if (this.updateAddressSuggestions)
        clearTimeout(this.updateAddressSuggestions)
    },
  },
  watch: {
    'contract.address'(address) {
      if (this.addressUpdateTimeout) {
        clearTimeout(this.addressUpdateTimeout)
        this.addressUpdateTimeout = null
      }
      if (address == this.lastSelectedSuggestion?.address) return
      this.addressUpdateTimeout = setTimeout(this.updateAddressSuggestions, 200)
    },
    'contract.citizenship'(citizenship) {
      if (!['ru', 'by'].includes(citizenship)) {
        this.contract.address = ''
        this.contract.addressData = null
        this.contract.city = null
        this.contract.postcode = null
      }
    },
    lastSelectedSuggestion() {
      this.$refs.form.validateField('address')
    },
  },
}
</script>
