<template>
  <div id="contract-not-found">
    <div class="gradient" />
    <div class="popup">
      <md-icon class="md-size-3x">{{ 'error_outline' }}</md-icon>
      <div class="title">{{ $t('contract.contractNotFound') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="scss">
#contract-not-found {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-image: url(~@/assets/SignedBg.webp);
  padding: 0 15px;

  > .gradient {
    background: linear-gradient(
      to top,
      #240000,
      rgba(108, 101, 101, 0.9) 40%,
      rgba(121, 116, 116, 0.65)
    );
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  > .popup {
    max-width: 360px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 15px;
    padding: 25px;
    background-color: white;
    border-radius: 4px;
    z-index: 1;

    > .md-icon {
      color: red;
    }

    > .title {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
</style>
